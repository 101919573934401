import React from 'react'

export default function whatsapp() {
  return (
    <a 
    href="
    https://wa.me/+573042705077?text=deseo%20m%C3%A1s%20informaci%C3%B3n%20acerca%20de%20
    " 
    class="whatsapp" 
    target="_blank"> 
     <i class="bx bxl-whatsapp"></i></a>
  )
}
