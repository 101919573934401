import React from "react";
import Inicio from "../modules/inicio";
import SectionAbout from "../modules/sectionAbout";
import ProximasFunciones from "../modules/proximasFunciones";
import Cartelera from "../modules/cartelera";
import Header from "../modules/header";
import Footer from "../modules/footer";
import WhatsApp from "../modules/WhatsApp";

export default function Home() {
  return (
    <>
      <Header/>
        {/* <Inicio /> */}
        <Cartelera />
       <SectionAbout />
      <ProximasFunciones />
      <WhatsApp/>
      <Footer/>
    </>
  );
}
