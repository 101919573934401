/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router';
import { FirebaseContext } from '../firebase'
import { Loader } from 'semantic-ui-react';
import HeaderDetalle from "./headerDetalle";
import FooterDetalle from "./footerDetalle";

export default function Detalle(props) {
  const { firebase } = useContext(FirebaseContext);
  const { id } = useParams();
  const [obra, guardarObra] = useState([]);
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
     setLoading(true);
    const obtenerObra = async () => {
      firebase.db
        .collection("carteleta")
        .where("slug", "==", id)
        .onSnapshot(controlarSnapshotObra);
    };
    obtenerObra();
  }, []);

  //====Snapshot permite utilizar la base de datos en tiempo real======

   function controlarSnapshotObra(snapshot) {
    const obra = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
    //almacenamos los resultados en el state
     guardarObra(obra);
      setLoading(false);
  }
  console.log(obra);
  return (
    <>
      <HeaderDetalle />
      {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        <main id="main">
          <section id="breadcrumbs" className="breadcrumbs" data-aos="fade-up">
            <div className="container">
              <div className="d-flex justify-content-between align-items-center">
                <h2>{obra[0].titulo}</h2>
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>{obra[0].titulo}</li>
                </ol>
              </div>
            </div>
          </section>

          <section id="portfolio-details" className="portfolio-details">
            <div className="container">
              <div className="row gy-4">
                <div className="col-lg-8">
                  <div className="portfolio-details-slider swiper">
                    <div className="swiper-wrapper align-items-center">
                      <div className="swiper-slide">
                        <img src={obra[0].imagenDetalle} alt="" />
                      </div>
                    </div>
                    <div className="swiper-pagination"></div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="portfolio-info">
                    <h3>Detalle</h3>
                    <ul>
                      <li>
                        <strong>Fecha</strong>:{obra[0].fechas}
                      </li>
                     {
                      obra[0].enlace ? (
                        <li>
                        <strong>Comprar entradas</strong>:{" "}
                        <a href={obra[0].enlace} target="_blank">
                          Aqui
                        </a>
                      </li>
                      ): (<></>)
                      }
                    </ul>
                  </div>
                  <div className="portfolio-description">
                    <h2>{obra[0].titulo}</h2>
                    <p>{obra[0].detalle}</p>
                  </div>
                  <a
                    href="/"
                    class="d-flex align-items-center justify-content-center active"
                  >
                    Volver
                  </a>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
      <FooterDetalle />
    </>
  );
}
