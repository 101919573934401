import React from 'react'

export default function Footer() {
  return (
    <>
      <footer id="footer">
        <div class="footer-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-6 footer-contact">
                <h3>
                  {" "}
                  <a href="/">
                    {" "}
                    <img
                      src="assets/img/LOGO-ANDRES-BRAVO-BLANCO.png"
                      className="logo"
                      alt="..."
                    />
                  </a>
                </h3>
                <p>
                  Medellin Colombia
                  <br />
                  <br />
                  <strong>Phone:</strong> +57 304 2705077
                  <br />
                  <strong>Email:</strong> corporacionjungla@gmail.com
                  <br />
                </p>
              </div>

              <div class="col-lg-4 col-md-6 footer-links">
                <h4>Menú principal</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i> <a href="#">Home</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="#about">Andrés Bravo</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="#portfolio">Cartelera</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="#services">Portafolio</a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-4 col-md-6 footer-links">
                <h4>Mis redes</h4>
                <p>Encuentranos en nuestras redes sociales</p>
                <div class="social-links mt-3">
                  <a
                    href="https://www.youtube.com/channel/UCPH2Wc9SsFLBHSFemRm63tw"
                    class="twitter"
                    target="_blanck"
                  >
                    <i class="bx bxl-youtube"></i>
                  </a>

                  <a
                    href="https://www.instagram.com/bravoperofeliz/"
                    class="instagram"
                    target="_blanck"
                  >
                    <i class="bx bxl-instagram"></i>
                  </a>
                  <a
                    href="https://www.tiktok.com/@bravoperofeliz"
                    class="google-plus"
                    target="_blanck"
                  >
                    <i class="bx bxl-tiktok"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container py-4">
          <div class="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>Apptomatic</span>
            </strong>
            . All Rights Reserved
          </div>
          <div class="credits">
            Designed by <a href="#">Apptomatic</a>
          </div>
        </div>
      </footer>
    </>
  );
}
