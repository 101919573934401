import React, { useEffect } from "react";

export default function HeaderDetalle() {
  const [scrolled, setScrolled] = React.useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });
  let navbarClasses = ["navbar"];
  if (scrolled) {
    navbarClasses.push("scrolled");
  }
  return (
    <header
      id="header"
      class="d-flex align-items-center"
      className={navbarClasses.join(" ")}
    >
      <div class="container d-flex align-items-center justify-content-between">
        <div class="logo">
          <h1 class="text-light">
            <a href="/">
              {" "}
              <img src="../assets/img/LOGO-ANDRES-BRAVO-BLANCO.png" alt="..." />
            </a>
          </h1>
          {/*           <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>
           */}{" "}
        </div>

        <nav id="navbar" class="navbar">
          <ul>
            <li>
              <a class="nav-link scrollto active" href="/">
                Home
              </a>
            </li>
            <li>
              <a class="nav-link scrollto" href="#footer">
                Contacto
              </a>
            </li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav>
      </div>
    </header>
  );
}
