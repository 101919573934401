/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useContext} from 'react';
import { FirebaseContext } from '../firebase';

export default function SectionAbout() {
  const { firebase } = useContext(FirebaseContext);
  const [about, guardarAbout] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      setLoading(true);
      const obtenerAbout =  async () => {
        firebase.db.collection("about")
          .onSnapshot(controlarSnapshotAbout);
      }
      obtenerAbout();
    }, []);
     //====Snapshot permite utilizar la base de datos en tiempo real======

     function controlarSnapshotAbout(snapshot) {
      const about = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      //almacenamos los resultados en el state
      guardarAbout(about);
      setLoading(false);
    }
    return (
      <section id="about" class="about">
        <div class="container">
          <div class="row">
            <div class="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start">
              <iframe
                width="100%"
                controls
                class="card-img-top"
                alt="..."
                src={about && about[0] && about[0].link}
                frameBorder="0"
                allowFullScreen
                title="video"
              />
            </div>
            <div class="col-xl-7 pt-4 pt-lg-0 d-flex align-items-stretch">
              <div class="content d-flex flex-column justify-content-center">
                <h3>{about && about[0] && about[0].title}</h3>
                <p>{about && about[0] && about[0].descripcion}</p>
                <div class="row">
                  <div class="col-md-12 icon-box" data-aos-delay="200">
                    <i class="bx bx-chalkboard"></i>
                    <p>{about && about[0] && about[0].about1} </p>
                  </div>
                  <div class="col-md-12 icon-box" data-aos-delay="300">
                    <i class="bx bxs-camera-movie"></i>
                    <p>{about && about[0] && about[0].about2}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}
