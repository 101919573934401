import React from "react";
import Home from "./routes/home";
import { Route, Routes } from "react-router";
import Detalle from "./modules/detalle";
import firebase, { FirebaseContext } from "./firebase";
export default function App() {
  return (
    <>
      <FirebaseContext.Provider value={{ firebase }}>
       
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="detalle/:id" element={<Detalle />} />
        </Routes>
       
      </FirebaseContext.Provider>
    </>
  );
}
