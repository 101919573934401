/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useContext} from 'react';
import { FirebaseContext } from '../firebase';
import { Link } from 'react-router-dom';
import { Loader } from "semantic-ui-react";

export default function Cartelera() {

  const { firebase } = useContext(FirebaseContext);
  const [cartelera, guardarCartelera] = useState([]);
    const [loading, setLoading] = useState(true);


  useEffect(() => {
    setLoading(true);
    const obtenerCartelera =  async () => {
      firebase.db.collection("carteleta")
        .orderBy('fecha', 'asc')
        .onSnapshot(controlarSnapshotCartelera);
    }
    obtenerCartelera();
  }, []);
            //====Snapshot permite utilizar la base de datos en tiempo real======

            function controlarSnapshotCartelera(snapshot) {
              const cartelera = snapshot.docs.map((doc) => {
                return {
                  id: doc.id,
                  ...doc.data(),
                };
              });
              //almacenamos los resultados en el state
              guardarCartelera(cartelera);
              setLoading(false);
            }
            console.log(cartelera, 'esta es la obra');
  return (
    <>
      {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        <section id="portfolio" className="portfolio section-bg">
          <div className="container">
            <div className="section-title">
              <span>Próximas funciones</span>
              <h2>Próximas funciones</h2>
            </div>

            <div className="row portfolio-container" data-aos-delay="300">
              {cartelera.map((obra) => (
                <div
                  key={obra.id}
                  className="col-lg-4 col-md-6 portfolio-item filter-app"
                >
                  <div className="portfolio-wrap">
                    <img src={obra.imgPrincipal} className="img-fluid" alt="" />
                    <div className="portfolio-info">
                      <h4>{obra.titulo}</h4>
                    </div>
                    <div className="portfolio-links">
                       <Link to={`/detalle/${obra.slug}`}> Más info</Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
}
