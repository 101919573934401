/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useContext, useEffect} from 'react'
import InfiniteCarousel from "react-leaf-carousel";
import { FirebaseContext } from "../firebase";
import { Loader } from "semantic-ui-react";


export default function ProximasFunciones() {

  const { firebase } = useContext(FirebaseContext);
  const [portafolio, guardarPortafolio] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    setLoading(true);
    const obtenerPortafolio = async () => {
      firebase.db
        .collection("portafolio")
        .onSnapshot(controlarSnapshotCartelera);
    };
    obtenerPortafolio();
  }, []);
  //====Snapshot permite utilizar la base de datos en tiempo real======

  function controlarSnapshotCartelera(snapshot) {
    const portafolio = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
    //almacenamos los resultados en el state
    guardarPortafolio(portafolio);
    setLoading(false);
  }

  return (
    <>
      {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        <section id="services" class="features">
          <div class="container">
            <div class="section-title">
              <span>Shows</span>
              <h2> Shows</h2>
            </div>
            <InfiniteCarousel
              breakpoints={[
                {
                  breakpoint: 500,
                  swipe: true,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    responsive: true,
                    autoCycle: true,
                    scrollOnDevice: true,
                  },
                  },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                  },
                  
                  autoCycle: true,
                },
              ]}
              dots={true}
              showSides={true}
              sidesOpacity={0.5}
              sideSize={0.20}
              slidesToScroll={4}
              slidesToShow={4}
              scrollOnDevice={true}
            >
              {portafolio.map((portafolio) => (
                <div key={portafolio.id} class=" d-flex align-items-stretch">
                  <div class="card">
                    <img
                      src={portafolio.imgPrincipal}
                      class="card-img-top"
                      alt="..."
                    />
                    <div class="card-body">
                      <i class="bx bx-tachometer"></i>
                      <h5 class="card-title">
                        <a href="">{portafolio.titulo}</a>
                      </h5>
                      <p class="card-text">{portafolio.detalle}</p>
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteCarousel>
          </div>
        </section>
      )}
    </>
  );
}
